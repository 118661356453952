import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/takeTime',
    name: 'takeTime',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/takeTime/index')
    
  },
  {
    path: '/my-permission',
    name: 'my_permission',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/takeTime/my_permission')
    
  }

]
