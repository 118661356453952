<template>
  <el-select style="width:100%" :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.status')  " filterable clearable :size="size" class="d-block">
    <el-option v-for="(status,index) in statuses" :key="'status-' + index" :label="status.name" :value="status.value">
    </el-option>
  </el-select>
</template>
<script> 
  import { i18n } from '@/utils/i18n';
import { getItem } from '../../../utils/storage';
  export default {
    name: 'user_status',
    props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
    },    
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
        return {
            selected: getItem('performance_type'),
            statuses:[
              {name: "To'lovli", id: 1, value: 1},
              {name: "To'lovsiz", id: 0,value:0},
            ]
        }
    },    
    methods: {     
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },

  }
</script>
