import Layout from '@/layouts/EmptyLayout'
export default [
  {
    path: '/advances',
    name: 'advances',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/advances/index')
    
  },
  {
    path: '/my-advances',
    name: 'my_advances',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/advances/my_advances')
    
  }
]


