import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/evaluation',
    name: 'evaluation',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/evaluation/index')
    
  }]
