import request from '@/utils/request'


export function index(params) {
  	return request({
  	  url: `/assent/attendance_report/${params.id}`,
  	  method: 'get',
      params
  	})
  }  


