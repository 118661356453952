import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/vacation',
    name: 'vacation',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/vacation/index')
    
  },
  {
    path: '/my-vacations',
    name: 'my_vacations',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/vacation/my_vacations')
    
  }]
