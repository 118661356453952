<template>
  <div>
    <el-input
      :placeholder="$t('message.search')"
      prefix-icon="el-icon-search"
      v-model="sarcheBlocks"
      size="small"
      round
      style="width: 100% !important"
    ></el-input>
  </div>
</template>
<script>
export default {};
</script>

