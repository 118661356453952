<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.staff')"
      filterable
      clearable
      :multiple="multiple"
      :size="size"
      class="d-block"
    >
      <el-option
        v-for="(staff, index) in items"
        :key="'staffs-' + index"
        :label="staff.name + ' ' + staff.last_name"
        :value="staff.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      required: false,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.staffs) {
      this.fetchStaffs();
    }
  },
  computed: {
    ...mapGetters({
      staffs: "staff/filter",
    }),
    items: function () {
      return this.staffs;
    },
  },
  methods: {
    ...mapActions({
      updateFilter: "staff/filter",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    fetchStaffs() {
      const query = { ...this.filter };
      this.updateFilter(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
  },
};
</script>
