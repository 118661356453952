<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.district')"
      filterable
      clearable
      :size="size"
      class="d-block"
    >
      <el-option
        v-for="(district, index) in items"
        :key="'districts-' + index"
        :label="district.name"
        :value="district.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    region_id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      immediate: true,
    },
    region_id: {
      handler: function () {
        this.selected = null;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.districts && this.districts.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      districts: "district/inventory",
    }),
    items: function () {
      return !this.region_id
        ? this.districts
        : _.filter(this.districts, ["region_id", this.region_id]);
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "district/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
