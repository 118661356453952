export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    branch_id: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    company_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
};
