<template>
  <div>
    <div id="chart">
      <apexchart
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import ApexCharts from "apexcharts";
export default {
  components: {
    ApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Уволенные",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "Новые сотрудники",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 66],
        },
      ],
      chartOptions: {
        colors: ["#409eff", "#67C23A"],
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            borderRadius: 15,
          },
        },
        dataLabels: {
          enabled: false,
          labels: {
            style: {
              colors: "#fff",
              fontSize: "12px",
            },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
          ],
          labels: {
            style: {
              colors: "#fff",
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          title: {
            text: "$ (thousands)",
          },
          labels: {
            style: {
              colors: "#fff",
              fontSize: "12px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
    };
  },
};
</script>
<style>
</style>
