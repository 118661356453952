import { index } from "@/api/myRequests";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data);
                    // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

   


};
