import request from '@/utils/request'
export function reasons(params) {
  	return request({
  	  url: `/assent/vacation/reasons`,
  	  method: 'get',
      params
  	})
  }  
  export function store(data) {
    return request({
      url: '/assent/vacation/store',
      method: 'post',
      data
    })
  }
  export function index(id) {
    return request({
      url: `/assent/vacation/${id}`,
      method: 'get',
      
    })
  }

