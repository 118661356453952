<template>
  <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.status')  " filterable clearable :size="size" class="d-block">
    <el-option v-for="(status,index) in statuses" :key="'status-' + index" :label="status.name" :value="status.value">
    </el-option>
  </el-select>
</template>
<script> 
  import { i18n } from '@/utils/i18n';
  export default {
    name: 'user_status',
    props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
    },    
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
        return {
            selected: null,
            statuses:[
              {name: i18n.t('message.active'), id: 1, value: 1},
              {name: i18n.t('message.inactive'), id: 2,value:2},
              {name: i18n.t('message.new'), id: 3,value: 3}
            ]
        }
    },    
    methods: {     
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },

  }
</script>
