import { index,destroy,saveAll,works,reasons, stafflist } from "@/api/additionalWork";

export const actions = {
  index({ commit }, id ) {
    return new Promise((resolve, reject) => {
        index(id)
            .then(res => {
                commit("SET_LIST", res.data.result.data.additionalworkcache);
                // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result.data.additionalworkcache);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  works({ commit }, id ) {
    return new Promise((resolve, reject) => {
        works(id)
            .then(res => {
                commit("SET_WORKS", res.data.result.data.works);
                commit("SET_STAFF_CHILD", res.data.result.data.children);
                // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result.data.works);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  reasons({ commit } ) {
    return new Promise((resolve, reject) => {
        reasons()
            .then(res => {
                commit("SET_REASONS", res.data.result.data.reasons);
                // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result.data.reasons);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  staff_list({ commit }, params) {
    return new Promise((resolve, reject) => {
      stafflist(params)
            .then(res => {
                commit("SET_STAFF_LIST", res.data.result.data.staffs);
                // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    saveAll({ commit }, data){
      return new Promise((resolve, reject) => {
        saveAll(data)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
