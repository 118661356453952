import Vue from 'vue'
import Vuex from 'vuex'
import {state} from './state'
 import modules from './modules'
import {actions} from './actions'
import {getters} from './getters'
import {mutations} from './mutations'

/**
 * Set Vue into Vue
 */
Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: true,
    modules: modules,
    state: state,
    actions: actions,
    getters: getters,
    mutations: mutations
});

export default store;
