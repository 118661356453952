import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/profile',
    name: 'profile',
    props: true,
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/profile/index')
    
  }]
