import request from '@/utils/request'


export function getStaff(id) {
  	return request({
  	  url: `/assent/staff-info/${id}`,
  	  method: 'get',
       
  	})
  } 
  export function index(id) {
  	return request({
  	  url: `/assent/take-assent/${id}`,
  	  method: 'get',
       
  	})
  }  
  export function staff(id) {
  	return request({
  	  url: `/assent/staff/${id}`,
  	  method: 'get',
       
  	})
  }  
  export function staffInfo(id) {
  	return request({
  	  url: `/assent/staff/${id}`,
  	  method: 'get',
       
  	})
  }  
  export function managers(id) {
  	return request({
  	  url: `/assent/managers/${id}`,
  	  method: 'get',
       
  	})
  }  

  export function check(params) {
  	return request({
  	  url: `/assent/staff/${params.id}`,
  	  method: 'post',
      params
       
  	})
  }  

// export function show(id) {
//     return request({
//       url: `/setting/cost-type/${id}`,
//       method: 'get'
//     })
//   }

  export function store(data) {
    return request({
      url: '/assent/take_assent',
      method: 'post',
      data
    })
  }

//   export function update(data) {
//     return request({
//       url: `/setting/cost-type/${data.id}`,
//       method: 'put',
//       data
//     })
//   }

//   export function destroy(id) {
//     return request({
//       url: `/setting/cost-type/${id}`,
//       method: 'delete',
//     })
//   }
