<template>
  <div>
    <div id="chart" class="mt-5">
      <apexchart
        type="pie"
        width="100%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
export default {
  components: {
    ApexCharts,
  },
  data() {
    return {
      series: [10, 40, 30, 20],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["15-30", "30-45", "45-60", "60-75"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
</style>
