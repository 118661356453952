<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.position')"
      filterable
      clearable
      :size="size"
      class="d-block"
    >
      <el-option
        v-for="(position, index) in items"
        :key="'positions-' + index"
        :label="position.name"
        :value="position.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    company_id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      immediate: true,
    },
    company_id: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.selected = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.positions && this.positions.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      positions: "position/inventory",
    }),
    items: function () {
      if (!this.company_id) {
        return this.positions;
      }
      if (this.id) {
        return this.positions;
      }

      let compId = this.company_id;
      let positions = _.filter(this.positions, function (pos) {
        return pos.company_ids.includes(compId);
      });
      if (positions.length == 0) {
        return this.positions;
      }
      return positions;
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "position/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
