import { index } from "@/api/profile";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.staff);
                    // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                  console.log(err);
                    reject(err);
                });
        });
    },
 
    // inventory({ commit }, params = {}) {
    //     return new Promise((resolve, reject) => {
    //         inventory(params).then(res => {
    //             commit("SET_INVENTORY", res.data.result.data.branches);
    //             resolve(res.data)
    //         }).catch(err => {
    //             reject(err.response.data)
    //         })
    //     })
    // },

    // show({ commit }, id) {
    //     return new Promise((resolve, reject) => {
    //         show(id)
    //             .then(res => {
    //                 commit('SET_MODEL', res.data.result.data.branch);
    //                 resolve(res);
    //             })
    //             .catch(err => {
    //                 reject(err.response.data);
    //             });
    //     });
    // },



    // update({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         update(data)
    //             .then(res => {
    //                 resolve(res);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },


};
