import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
// import { filter } from "./properties/filter";
// import store from '@/store/index'
// import { model } from "./properties/model";
// import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, branches) => {              
        state.list = branches;        
    },
    SET_WORKS: (state, branches) => {              
      state.works = branches;        
    },
    SET_REASONS: (state, reasons) => {              
      state.reasons = reasons;        
    },
    SET_STAFF_CHILD: (state, staff_child) => {              
      state.staff_child = staff_child;        
    },
    SET_STAFF_LIST: (state, staff_list) => {              
      state.staff_list = staff_list;        
    },
    SET_INVENTORY: (state, branches) => (state.inventory = branches),
    SET_SORT: (state, sort) => (state.sort = sort),
    // SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
   
    REFRESH: (state) => {
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    }
};
