export const en_messages = {
  n: "№",
    create: "Create",
    settings: "Settings",
    complaint:"Claim",
    avance:"Prepayment",
    report_card:"Report card",
    additional_work:"Additional work",
    waiting:"Considered",
    received:"Accepted",
    denied:"Rejected",
    approved:"Approved",
    rejected:"Rejected",
    qty:"Quantity",
    vacation: "Vacation",
    my_requests: "My Requests",
    my_complaints: "My complaints",
    permission_ask: "Permission",
    my_permission_requests: "My permission requests",
    my_advance_requests: "My advance requests",
    my_vacation_requests: "My vacation requests",
    user: "User",
    reason: "Cause",
    left_date: "Time to leave",
    come_date: "Arrival time",
    status: "Status",
    comment: "Comment",
    request_data: "Request date",
    advance_amount: "Advance amount",
    back: "back",
    day: "Day",
    hour: "Hour",
    minut:'Minute',
    summa: "Sum",
    information_come_towork: "Information that the employee came to work and did not come",
    today_working_hours: "Working hours today",
    total_working_hours: "Total working hours",
    total_working_days: "Total working days",
    time: "Time",
    data_time: "Date and time",
    from: "From",
    to: "To",
    manager: "Head",
    title: "Theme",
    work_type: "Performance type",
    count: "Number",
    confirm: "Confirmation",
    whom_sent: "To whom it was sent",
    message_text: "Message text",
    sent_time: "Time sent",
    ask_avance:"Ask for an advance",
    ask_vacation:"Ask for a vacation",
    dedline:"Duration",
    send_complaint:"Send complaint",
    details:'Details',
    created_at: "Created at",
    updated_at: "Updated at",
    name: "Name",
    my_bonuses: "My Bonuses",
    amount: "Amount",
    my_penalties:"My fines",
    user_information:"Profile information",
    user_profile:"User profile",
    limit_work_time:"Work rate (hour)",
    actual_working_time:"Actual working time (hour)",
    salary: "Salary",
    bonuse: "Bonus",
    penalties:"Penalty",
    requirement: "Demand",
    company: "Company",
    branch_a: "Branch",
    department: "Department",
    position: "Position",
    accept_vacation_managment:"Месяц отпуска",
    not_attached:"Не прикреплен"

}
