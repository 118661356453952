<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.phone_number')"
      filterable
      clearable
      :disabled="disabled"
      :size="size"
      class="d-block"
      :multiple="multiple"
    >
      <el-option
        v-for="(guestPhoneNumber, index) in guestPhoneNumbersList"
        :key="'guestPhoneNumbers-' + index"
        :label="guestPhoneNumber.phone_number"
        :value="guestPhoneNumber.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    guest_id: {
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    guest_id: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.selected = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.guestPhoneNumbers && this.guestPhoneNumbers.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      guestPhoneNumbers: "guestPhoneNumber/inventory",
    }),
    guestPhoneNumbersList: function () {
      if (!this.guest_id) {
        return this.guestPhoneNumbers;
      }
      if (this.guest_id) {
        let filteredPhoneNumbers = _.filter(this.guestPhoneNumbers, [
          "guest_id",
          this.guest_id,
        ]);
        if (filteredPhoneNumbers.length == 1) {
          this.dispatch(filteredPhoneNumbers[0].id);
          return filteredPhoneNumbers;
        }
        return filteredPhoneNumbers;
      }
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "guestPhoneNumber/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
      let phoneNumberFound = _.find(this.guestPhoneNumbers, ["id", e]);
      if (phoneNumberFound) {
        this.$emit("refreshGuestId", phoneNumberFound.guest_id);
      }
    },
  },
};
</script>
