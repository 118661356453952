<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.income')" filterable clearable :multiple="multiple"
      :size="size" class="d-block">
      <el-option v-for="(income,index) in incomes" :key="'incomes-' + index" :label="income.name" :value="income.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import { i18n  } from '@/utils/i18n';
  export default {
    props: {
        size: {
            default: 'small'
        },
        placeholder: {
            default: null,
        },
        id:{
            default: null
        },
        multiple:{
            type: Boolean,
            default: false,
        }
    },
     watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.incomes && this.incomes.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        incomes: 'income/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'income/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)       
        this.selected = e
      }
    },
  }

</script>
